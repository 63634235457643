<template>
    <div class="alarmManagement">
        <el-card class="box-card">
            <div class="title">报警管理</div>
            <div class="title-top" style="margin-bottom: 2vh">
                <div class="el-title-item">
                    <el-form ref="form" :model="logisticsForm">
                        <el-input
                            style="margin-top: 10px;margin-left: 20px;width:200px;height: 27px;"
                            v-model="logisticsForm.names"
                            placeholder="请输入节点编号"
                        ></el-input>
                    </el-form>
                </div>
            </div>
            <!-- 报警圆 -->
            <div class="siren"></div>
            <img class="imgs" src="@/assets/others/baojing.png" alt="">
            <div class="flush" @click="refresh">
                <img style="width: 25px;height: 25px;" src="@/assets/others/shuaxin.png" alt="">
                <div>刷新</div>
            </div>
            
            <div class="reset" @click="removeForm">
                <img style="width: 20px;height: 20px;" src="@/assets/others/reset.jpg" alt="">
                <div>重置</div>
            </div>
            <el-button style="float: right;" type="primary" icon="el-icon-search" size="mini" @click="searchs">搜索</el-button>
            <el-table
            v-loading="loading"
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            >
            <el-table-column
                label="ID"
                prop="id"
                show-overflow-tooltip
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="节点编号"
                prop="sensorCode"
                show-overflow-tooltip
                align="center"
            >
            </el-table-column>
            <el-table-column
                label="设备名称"
                prop="deviceName"
                align="center"
                show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
                label="报警值"
                prop="value"
                show-overflow-tooltip
                align="center"
            ></el-table-column>
            <el-table-column
                label="报警时间"
                prop="alarmTime"
                show-overflow-tooltip
                align="center"
            ></el-table-column>
            <el-table-column label="操作" align="center" width="150">
                <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-delete"
                    @click="del(scope.row)"
                ></el-button>
                </template>
            </el-table-column>
            </el-table>
            <div class="block pagingBox">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="total"
                >
                </el-pagination>
            </div>
            <!-- 报警弹框 -->
            <div class="alert" v-show="showBox">
                <div class="alert-title"> 
                    <h4 style="margin-top:10px;margin-left: 10px;width: 100px; float: left;">设备报警</h4>
                    <img class="imgs2" src="@/assets/others/guanbi.png" alt="" @click="closeBox">
                </div>
                <div class="alert-content" :model="tableData">
                    <img class="imgs3" src="@/assets/others/baojing2.png" alt="">
                    <div style="color: #000;">{{alarmTime}}</div>
                    <div style="color: red;">value>100</div>
                </div>
                
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data (){
        return{
            upTableData: {
                releaseUser: '',
                context: '',
                releaseDate: '',
            },
            upTableDatas: {
                releaseUser: '',
                context: '',
                releaseDate: '',
            },
            value1: "",
            tableData: [],
            page: 1,
            size: 10,
            total: 0,
            loading:false,
            searchStatus: false,
            logisticsForm: {
                names: '',
                region: -1,
            },
            updateForm:false,
            showBox:false,
            boxPrompt: '',

            rules: {
                releaseUser: { required: true, message: '发布人不可为空', trigger: 'change' },
                context: { required: true,message: '发布内容不能为空', trigger: 'blur' },
                releaseDate: { required: true,message: '时间不能为空', trigger: 'blur' },
                // tel: [{ required: true,message: '手机号不能为空' }, { validator: checkAge, trigger: 'blur' }],
            },
            alarmTime:'',
            value: '',
        }
    },
    mounted(){
        this.requrstList();
    },
    methods:{
        handleSizeChange(val) {
            this.size = val;
            this.requrstList();
        },
        handleCurrentChange(val) {
            this.page = val;
            this.requrstList();
        },
        closeBox(){
            this.showBox = false;
        },
        //请求列表数据
        requrstList(where) {
            let that = this;
            this.$get('/trigger/triggerHistoryListMan', { page: this.page, size: this.size,  sensorCode: where || '' }).then((res) => {
                //console.log(res)
                if (res.data.state == 'success') {
                    that.tableData = [];
                    that.total = res.data.count;
                    res.data.datas.forEach((item) => {
                        if (item.del !== 1) {
                            that.tableData.push(item);
                            if(item.value >= 100){
                                that.showBox = true;
                                that.alarmTime = item.alarmTime;
                            }

                        } 
                    });

                    // that.tableData = res.data.datas;
                    // for(let i=0;i<that.tableData.length;i++){
                    //     if(that.tableData[i].value >= 100){
                    //         that.showBox = true;
                    //         that.alarmTime = that.tableData[i].alarmTime;
                    //     }else{
                    //         that.showBox = false;
                    //     }
                    // }
                }
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            });
        },

        toggleSelection(rows) {
            if (rows) {
                rows.forEach((row) => {
                this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        //删除
        del(item) {
            this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then(() => {
                this.$post("/trigger/delTriggerMan", {
                id: item.id,
                }).then((res) => {
                if (res.data.state == "success") {
                    this.$message.success("删除成功");
                    this.requrstList();
                } else {
                    this.$message.info(res.data.msg);
                }
                });
            }).catch(() => {
                this.$message({
                type: "info",
                message: "已取消删除",
                });
            });
        },
        recoveryUse(data){
            this.$get('/userManage/updateUserInfoTwo', data).then((res) => {
                //console.log(res)
                this.requrstList();
            })
        },
        //刷新
        refresh() {
            this.loading = true;
            //   this.requrstList();
            if (!this.searchStatus) {
                this.requrstList();
            } else {
                this.searchs();
            }
        },
        //搜索
        searchs() {
            let names = this.logisticsForm.names;
            this.page = 1;
            let level = this.logisticsForm.region;
            if (level == -1) level = '';
            this.loading = true;
            this.searchStatus = true;
            if (names == '' || !names) {
                this.searchStatus = false;
            }
            this.requrstList(names);
        },
        //重置
        removeForm() {
            this.loading = true;
            this.logisticsForm.names = '';
            this.logisticsForm.region = -1;
            this.requrstList();
        },
        //分组监听
        groupChange(val) {
            //console.log(val);
            this.upTableData.level = val;
        },

    }
}
</script>

<style scoped lang="less">
    .title{
        border-bottom: 1px #999 solid ;
        padding-bottom: 10px;
        font-size: 12px;
        font-weight: bold;
    }
    .box-card{
        width: 98%;
        // height: 850px;
        margin: 0 auto;
        border-radius: 10px;
        overflow: hidden;
    }
    .flush{
        width: 60px;
        height: 22px;
        border: 1px solid #DCDFE6;
        margin-left: 20px;
        border-radius: 5px;
    }
    .flush div{
        font-size: 14px;
        float: right;
        margin-right: 6px;
    }
    .siren{
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: red;
        position: absolute;
        top: 67px;
        left: 290px;
    }
    .imgs{
        position: absolute;
        top: 58px;
        left: 315px;
    }
    .reset{
        width: 70px;
        height: 22px;
        border: 1px solid #DCDFE6;
        margin-left: 20px;
        border-radius: 5px;
        float: right;
        text-align: center;
        padding-top: 5px;
    }
    .reset div{
        font-size: 14px;
        float: right;
        margin-right: 10px;
    }
    .el-pagination{
        text-align: center;
    }
    .alert{
        // display: none;
        position: fixed;
        top: 40%;
        left: 42%;
        width: 330px;
        height: 110px;
        border: 1px #000 solid;
        border-radius: 8px;
        background-color: #fff;
    }
    .alert-title{
        // width: 100%;
        height: 35px;
        border-bottom: 1px #ccc solid;
        margin: 0 10px;
        overflow: hidden;
    }
    .imgs2{
        cursor: pointer;
        width: 30px;
        height: 30px;
        float: right;
        margin-top: 5px;
        margin-right: 10px;
    }
    .imgs3{
        margin-top: 20px;
        margin-left: 25px;
        float: left;
    }
    .alert-content div{
        font-size: 13px;
        float: left;
        margin-top: 33px;
        margin-left: 20px;
        font-weight: bold;
    }
    

</style>